<template>
  <div class="add_campaign">
    <b-card>
      <div class="heading">
        <p>
          Add Campaign
        </p>
      </div>
      <!-- form -->
      <validation-observer ref="addCampaignForm">
        <b-form
          class="mt-2"
          style="
          width: 100%"
          @submit.prevent
        >
          <b-col
            md="6"
            class="mx-auto"
          >
            <b-form-group
              text-bolder
              label="Campaign Name"
              label-for="blog-edit-title"
              class="mb-2 campaign-label"
            >
              <validation-provider
                v-slot="{ errors }"
                name="campaign name"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="blogData.name"
                  placeholder="Please enter Campaign name here"
                  style="height: 42px"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- devices select-->
          <b-col
            md="6"
            class="mx-auto"
          >
            <b-form-group
              label="Screens"
              label-for="blog-edit-category"
              class="campaign-label"
            >
              <v-select
                v-model="selected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                label="name"
                :options="filteredDevicesOptions"
                class="search"
                @input="selectDevicesFunc(selected)"
                @search="onSearch"
              />
            </b-form-group>
          </b-col>
          <b-row
            md="12"
            lg="12"
            class="mb-2 mt-4 px-1"
          >
            <b-col
              md="4"
              lg="4"
            >
              <b-form-group
                label="Select Starting Date"
                label-for="blog-edit-category"
                class="mb-2 campaign-label"
              >
                <b-form-datepicker
                  v-model="blogData.start_date"
                  :state="blogData.start_date ? true : null"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="4"
              lg="4"
            >
              <b-form-group
                label="Select Ending Date"
                label-for="blog-edit-category"
                class="campaign-label"
              >
                <b-form-datepicker
                  v-model="blogData.end_date"
                  :min="new Date(blogData.start_date)"
                  :disabled="!blogData.start_date"
                  :state="blogData.start_date && blogData.end_date ? new Date(blogData.start_date) > new Date(blogData.end_date) ? false : true : null"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="4"
              lg="4"
            >
              <b-form-group
                label="Select Time Zone"
                label-for="blog-edit-category"
                class="campaign-label"
              >
                <v-select
                  v-model="blogData.timezone"
                  class="zindex-2"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  select-size="3"
                  :clearable="false"
                  :searchable="true"
                  :options="timezones"
                  placeholder="Select Time Zone"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- devices Group-->
          <!-- <b-col
            md="6"
            class="mx-auto"
          >
            <b-form-group
              label="Venues Groups"
              label-for="blog-edit-category"
              class="mb-2 campaign-label"
            >
              <v-select
                v-model="groupSelected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                label="name"
                :options="filteredGroupsOptions"
                class="search"
                @input="selectGroupFunc(groupSelected)"
              />
            </b-form-group>
          </b-col> -->
          <b-col />
        </b-form>
      </validation-observer>
      <Calendar
        v-model="date"
        :attributes="attributes"
        is-expanded
        @dayclick="onDayClick"
      />
      <div
        class="d-flex justify-content-center"
      >
        <b-button
          variant="outline-primary"
          class="my-1 mx-1"
          :disabled="disableSetTime"
          @click="setTimeForSelectedDates"
        >
          Set Time
        </b-button>
        <b-button
          v-if="ifTimeSlotsExists"
          variant="outline-primary"
          class="my-1 mx-1"
          @click="editTimeForSelectedDates"
        >
          View Schedule
        </b-button>
      </div>
      <!--/ form -->
      <BannerAdsMedia
        :max-time-limit="TimeLimitCondition.maxBannerAdsMediaCondition"
        @addMediaToCampaign="addMediaToCampaignFile"
      />
      <AddedMediaToCampaign
        ref="add-media-cam-ref"
        :added-media="addedFilesToCampaign"
        :max-time-limit="TimeLimitCondition.maxBannerAdsMediaCondition"
        :is-draggable="true"
        @removeMediaFromList="deleteMediaFromCampaign"
        @changedOrderOfList="changedOrderOfList"
      />
      <AddTimeSlotsInternalAdCampaigns
        ref="add-time-slots"
        :dates-for-time-slots="selectedDates"
        :array-of-dates-and-time-slots="finalDatesWithTimeSlots"
        @addDatesWithTimeSlotsToArray="addDatesWithTimeSlotsToArray"
        @notSetTimeSlot="notSetTimeSlot"
      />
      <viewListDatesWithTimeSlots
        ref="view-time-slots"
        :array-of-dates-and-time-slots="finalDatesWithTimeSlots"
        @addDatesWithTimeSlotsToArrayAfterViewingList="addDatesWithTimeSlotsToArrayAfterViewingList"
      />
      <div class="d-flex justify-content-center mt-0 ">
        <b-button
          variant="outline-primary"
          class="my-1"
          @click="reviewCampaign"
        >
          Review
        </b-button>
        <b-button
          variant="primary"
          class="m-1"
          :disabled="spinner"
          @click="createCampaign"
        >
          <div
            v-if="spinner"
            class="spinner"
          >
            <b-spinner
              small
            />
          </div>
          Create
        </b-button>
        <b-button
          class="choose my-1"
          @click="cancelCampaign"
        >
          Cancel
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BForm, BCol, BFormGroup, BFormInput, BButton, VBModal, BSpinner,
  BFormDatepicker, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { Calendar } from 'v-calendar'
import Vue from 'vue'
// import moment from 'moment'
import AccountTypes from '@/common/enums/accountTypeEnum'
import MediaType from '@/common/enums/mediaTypeEnum'
import BannerAdsMedia from '@/components/common/BannerAdsMedia.vue'
import AddedMediaToCampaign from '@/components/common/AddedMediaToCampaign.vue'
import TimeLimitCondition from '@/common/config'
import { showErrorMessage, showToast, createQueryString } from '@/common/global/functions'
import { convertToSchedulerFormatToUTCForInternal, getAllTimezones, concatenateDateWithTimeSlotsForInternal } from '@/common/global/calenderFunctions'
import store from '@/store'
import MemberPermissions from '@/common/enums/memberPermissionsEnum'
import AddTimeSlotsInternalAdCampaigns from '@/components/bannerads/AddTimeSlotsInernalAdCampains.vue'
import viewListDatesWithTimeSlots from '@/components/bannerads/viewListDatesWithTimeSlots.vue'

Vue.component('Calendar', Calendar)

export default {
  components: {
    AddedMediaToCampaign,
    BCard,
    BForm,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BButton,
    BannerAdsMedia,
    BSpinner,
    // validations
    ValidationProvider,
    ValidationObserver,
    Calendar,
    AddTimeSlotsInternalAdCampaigns,
    viewListDatesWithTimeSlots,
    BFormDatepicker,
    BRow,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  beforeRouteEnter(to, from, next) {
    const memberPermission = store.getters['user/getPermissionOfMember'](MemberPermissions.INTERNAL_NETWORK_ADS)
    const adminRole = store.getters['user/getUserRole']
    if (memberPermission || (adminRole === AccountTypes.ADMIN)) {
      next()
    } else {
      next({
        name: 'dashboard',
      })
    }
  },
  data() {
    return {
      TimeLimitCondition,
      MediaType,
      addedFilesToCampaign: [],
      spinner: false,
      search: '',
      selected: [],
      allDevices: [],
      allGroups: [],
      groupSelected: [],
      blogData: {
        name: '',
        banner_has_group: [],
        banner_has_device: [],
        banner_has_media: [],
        banner_has_schedule: [],
        start_date: null,
        end_date: null,
        timezone: null,
      },
      // validation
      required,
      AccountTypes,
      date: new Date(),
      ifTimeSlotsExists: false,
      disableSetTime: true,
      days: [],
      finalDatesWithTimeSlots: [],
      arrayOfDatesCollected: [],
      selectedDates: [],
      convertedDatesAndslots: [],
      timezones: [],
    }
  },
  computed: {
    filteredDevicesOptions() {
      return this.allDevices.filter(n => !this.selected.map(device => device.id).includes(n.id))
    },
    userRole() {
      return this.$store.getters['user/getUserRole']
    },
    dates() {
      return this.days.map(day => day.date)
    },
    attributes() {
      const currentAttributes = this.dates.map(date => ({
        highlight: true,
        dates: date,
      }))
      const previousAttributes = this.arrayOfDatesCollected.map(id => ({
        bar: 'red',
        dates: id,
      }))

      return [...currentAttributes, ...previousAttributes]
    },
  },
  async mounted() {
    await this.getAllDevices()
    this.timezones = await getAllTimezones()
  },
  methods: {
    async onSearch(query, loading) {
      this.search = query
      if (this.search.length) {
        loading(true)
        await this.getAllDevices()
        loading(false)
      } else {
        loading(false)
      }
    },
    async getAllDevices() {
      const queryParams = {
        offset: 0,
        limit: 20,
        search_query: this.search,
      }
      const queryString = createQueryString(queryParams)
      if (this.userRole === this.AccountTypes.USER) {
        try {
          const {
            data: {
              data,
            },
          } = await this.$axios.get(`user/device?${queryString}`)
          this.allDevices = data.results
        } catch {
        //
        }
      } else {
        try {
          const { data: { data } } = await this.$axios.get(`admin/device/${this.$route.params.userId}?${queryString}`)
          this.allDevices = data.results
        } catch {
          //
        }
      }
    },
    updateIdsOfMediaInPayload() {
      this.blogData.banner_has_media = this.addedFilesToCampaign.map(res => res.id)
    },
    addMediaToCampaignFile(object) {
      this.addedFilesToCampaign.push(object)
      this.updateIdsOfMediaInPayload()
    },
    deleteMediaFromCampaign(index) {
      this.addedFilesToCampaign.splice(index, 1)
      this.updateIdsOfMediaInPayload()
    },
    changedOrderOfList(array) {
      this.addedFilesToCampaign = array
      this.updateIdsOfMediaInPayload()
    },
    selectDevicesFunc(selected) {
      this.blogData.banner_has_device = selected.map(res => res.id)
    },
    async createCampaign() {
      this.blogData.banner_has_schedule = await concatenateDateWithTimeSlotsForInternal(this.finalDatesWithTimeSlots)
      this.blogData.banner_has_schedule = await convertToSchedulerFormatToUTCForInternal(this.blogData.timezone, this.blogData.banner_has_schedule)
      this.$refs.addCampaignForm.validate().then(async success => {
        if (success) {
          if (!this.blogData.banner_has_device.length && !this.blogData.banner_has_group.length) {
            showToast('Add Internal Network Ad', 'Please add devices to create internal network ad!', 'danger', 4000)
          } else if (!this.blogData.banner_has_media.length) {
            showToast('Add Internal Network Ad', 'Please add media to create internal network ad!', 'danger', 4000)
          } else if (!this.blogData.start_date) {
            showToast('Add Internal Network Ad', 'Please add Starting date to create internal network ad!', 'danger', 4000)
          } else if (!this.blogData.end_date) {
            showToast('Add Internal Network Ad', 'Please add Ending date to create internal network ad!', 'danger', 4000)
          } else if (!this.validateDates()) {
            showToast('Add Internal Network Ad', 'Ending date should be ahead of Starting date!', 'danger', 4000)
          } else if (!this.blogData.banner_has_schedule.length) {
            showToast('Add Internal Network Ad', 'Please add schedule to create internal network ad!', 'danger', 4000)
          } else if (!this.blogData.timezone) {
            showToast('Add Internal Network Ad', 'Please add Time Zone to create internal network ad!', 'danger', 4000)
          } else {
            this.spinner = true
            await this.addNewBannerCampaign()
            this.spinner = false
          }
        }
      })
    },
    async addNewBannerCampaign() {
      if (this.userRole === this.AccountTypes.USER) {
        try {
          await this.$axios
            .post('banner-campaigns', this.blogData)
          showToast('Add Internal Network Ad', 'Internal Network Ad has been added successfully!')
          await this.$router.push('/banner-ads')
        } catch ({
          response: {
            data: {
              statusCode, message,
            },
          },
        }) {
          if (showErrorMessage(statusCode)) {
            showToast('Add Internal Network Ad', message.toString(), 'danger')
          }
        }
      } else {
        try {
          await this.$axios
            .post('admin/banner-campaign', this.blogData, {
              headers: {
                userHasGroupId: this.$route.params.userId,
              },
            })
          showToast('Add Internal Network Ad', 'Internal Network Ad has been added successfully!')
          await this.$router.push(`/users/${this.$route.params.userId}/user-banner-ads`)
        } catch ({
          response: {
            data: {
              statusCode, message,
            },
          },
        }) {
          if (showErrorMessage(statusCode)) {
            showToast('Add Internal Network Ad', message.toString(), 'danger')
          }
        }
      }
    },
    cancelCampaign() {
      if (this.userRole === this.AccountTypes.ADMIN) {
        this.$router.push(`/users/${this.$route.params.userId}/user-banner-ads`)
      } else {
        this.$router.push('/banner-ads')
      }
    },
    async reviewCampaign() {
      if (this.addedFilesToCampaign) {
        await this.$store.dispatch('lightBox/lightBoxData', this.addedFilesToCampaign)
      }
    },
    async onDayClick(day) {
      const idx = this.days.findIndex(d => d.id === day.id)
      if (idx >= 0) {
        this.days.splice(idx, 1)
        if (this.days.length === 0) {
          this.disableSetTime = true
          this.setAllDatesAvailable()
        }
      } else {
        this.disableSetTime = false
        this.days.push({
          id: day.id,
          date: day.date,
        })
      }
    },
    setTimeForSelectedDates() {
      this.days.forEach(element => this.selectedDates.push(element.id))
      this.$refs['add-time-slots'].show()
    },
    async editTimeForSelectedDates() {
      this.setAllDatesAvailable()
      this.disableSetTime = true
      this.$refs['view-time-slots'].show()
    },
    addDatesWithTimeSlotsToArray(array) {
      this.days.forEach(element => this.arrayOfDatesCollected.push(element.id))
      this.days = []
      this.selectedDates = []
      this.disableSetTime = true
      this.setAllDatesAvailable()
      this.finalDatesWithTimeSlots = array
      if (this.finalDatesWithTimeSlots.length > 0) {
        this.ifTimeSlotsExists = true
      }
    },
    notSetTimeSlot() {
      this.setAllDatesAvailable()
      this.disableSetTime = true
    },
    setAllDatesAvailable() {
      this.days = []
      this.selectedDates = []
    },
    async addDatesWithTimeSlotsToArrayAfterViewingList(array) {
      this.arrayOfDatesCollected = []
      this.finalDatesWithTimeSlots = array
      await this.finalDatesWithTimeSlots.forEach(element => this.arrayOfDatesCollected.push(element.key))
      if (this.finalDatesWithTimeSlots.length === 0) {
        this.ifTimeSlotsExists = false
      }
    },
    validateDates() {
      if (this.blogData.start_date && this.blogData.end_date && new Date(this.blogData.start_date) <= new Date(this.blogData.end_date)) {
        return true
      }
      return false
    },
  },
}
</script>

<style src="../../../node_modules/vue-it-bigger/dist/vue-it-bigger.min.css"></style>
<style lang="scss">
.add_campaign {
  .search {
    height: 50px !important;
    .vs__dropdown-menu {
      max-height: 100px !important;
      overflow-y: auto !important;
    }
    .vs__dropdown-toggle{
      max-height: 70px !important;
      overflow-y: auto !important;
    }
    .vs__search, .vs__search:focus{
      font-size: medium !important;
    }
  }
  .campaign-label{
    font-size: 13px;
    line-height: 18px;
    color: #323232;
    font-weight: 600;
    font-family: "Montserrat";
  }
}
.heading{
  font-size: 20px;
  line-height: 44px;
  color: #1f58b5;
  font-weight: 600;
  font-family: "Montserrat";
  text-align: center;
}
.mediaText{
  font-size: 14px;
  line-height: 22px;
  color: #323232;
  font-weight: 600;
  font-family: "Montserrat";
  display: flex;
  justify-content: center;
  margin-top: 2rem;

}
.addDevice{
 width: 171px;
height: 65px;
border-radius: 10px;
background-color: #ffffff;
padding: 25px;
}
.text{
font-size: 12px;
color: #5e5873;
font-weight: 500;
font-family: "Montserrat";
}
.ml-0{
  font-size: 10px;
color: #6e6b7b;
font-weight: 400;
font-family: "Montserrat";
}
.choose{
  display: flex;
  background-color: #e0dee1 !important;
  color: #6e6b7b !important;
  border-color: #e0dee1 !important;
}
.modal1{
  padding: 0 !important;
}
.vc-bars{
  width: 20% !important;
}
.vc-day-content:focus {
  font-weight: normal !important;
}

</style>
