<template>
  <div>
    <div class="position-relative pt-2">
      <div
        id="map"
        class="map-div"
      />
    </div>
  </div>
</template>

<script>
import OverlappingMarkerSpiderfier from 'overlapping-marker-spiderfier'
import {
  drawCircle,
  createResetZoomButton,
  devicesMarkerFunc,
  markerclusterer,
  moveToLocation,
} from '@/common/global/mapFunctions'
import darkThemeStyle from '@/common/global/darkThemeMap'

export default {
  components: {
  },
  props: {
    centerOfCircle: {
      type: Object,
      required: true,
    },
    radius: {
      type: Number,
      required: true,
    },
    id: {
      type: String || Function || HTMLInputElement,
      required: true,
    },
    shouldDisableOptions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      map: '',
      marker: [],
      markers: [],
      drawingManager: {},
      selectedRadius: this.radius,
      center: {},
      locationAddress: '',
      isDeviceSelected: false,
      currentInfoWindow: null,
      ifAllSelected: [],
      allDevicesFromParent: [],
      selectedDevices: [],
      svgMarker: {
        path: 'M384,1.42108547e-14 L384,298.666667 L213.333,298.666 L213.333,362.666 L298.666667,362.666667 L298.666667,405.333333 L85.3333333,405.333333 L85.3333333,362.666667 L170.666,362.666 L170.666,298.666 L1.42108547e-14,298.666667 L1.42108547e-14,1.42108547e-14 L384,1.42108547e-14 Z M341.333333,42.6666667 L42.6666667,42.6666667 L42.6666667,256 L341.333333,256 L341.333333,42.6666667 Z M320,64 L320,234.666667 L64,234.666667 L64,64 L320,64 Z',
        fillColor: 'red',
        fillOpacity: 1,
        strokeWeight: 0,
        rotation: 0,
        scale: 0.05,
        // eslint-disable-next-line no-undef
        anchor: new google.maps.Point(200.333333, 20.6666667),
      },
      markerClusterer: null,
      selectedSvgMarker: {
        path: 'M384,1.42108547e-14 L384,298.666667 L213.333,298.666 L213.333,362.666 L298.666667,362.666667 L298.666667,405.333333 L85.3333333,405.333333 L85.3333333,362.666667 L170.666,362.666 L170.666,298.666 L1.42108547e-14,298.666667 L1.42108547e-14,1.42108547e-14 L384,1.42108547e-14 Z M341.333333,42.6666667 L42.6666667,42.6666667 L42.6666667,256 L341.333333,256 L341.333333,42.6666667 Z M320,64 L320,234.666667 L64,234.666667 L64,64 L320,64 Z',
        fillColor: 'green',
        fillOpacity: 1,
        strokeWeight: 0,
        rotation: 0,
        scale: 0.05,
        // eslint-disable-next-line no-undef
        anchor: new google.maps.Point(200.333333, 20.6666667),
        markerClusterer: null,
      },
      oms: null,
    }
  },
  computed: {
    areAllDevicesSelected() {
      return this.markers.length > 0 && this.ifAllSelected.length === this.markers.length
    },
  },
  watch: {
    centerOfCircle: {
      handler() {
        this.center = this.centerOfCircle
      },
      immediate: true,
      deep: true,
    },
    'center.lat': {
      handler() {
        this.$emit('circleCenterSetFunc', this.center)
      },
    },
    locationAddress() {
      this.$emit('locationAddressSetFunc', this.locationAddress)
    },
  },
  mounted() {
    this.initAutocomplete()
    this.$emit('circleCenterSetFunc', this.center)
  },
  methods: {
    selectedRadiusFunc(radius) {
      this.selectedRadius = radius
      this.circle.setMap(null)
      this.circleFunc()
      this.distanceChanged(this.selectedRadius)
      this.$emit('circleRadiusSetFunc', this.selectedRadius)
    },
    initAutocomplete() {
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: { lat: this.center.lat, lng: this.center.lng },
        zoom: 11,
        mapTypeId: 'roadmap',
        disableDefaultUI: !!this.shouldDisableOptions,
        draggable: !this.shouldDisableOptions,
        disableDoubleClickZoom: !!this.shouldDisableOptions,
        streetViewControl: false,
        styles: darkThemeStyle,
        mapTypeControl: false,
      })

      this.oms = new OverlappingMarkerSpiderfier(this.map, {
        keepSpiderfied: true, // Keeps the spiderfied markers expanded until clicked outside
        nearbyDistance: 50, // Pixels threshold for clustering
        circleFootSeparation: 100, // Increased separation in circular layout
        spiralFootSeparation: 200, // Increased separation in spiral layout
        spiralLengthStart: 20, // Starting length for the spiral
        spiralLengthFactor: 30, // Spiral expansion factor
        markersWontHide: true,
        markersWontMove: true,
        circleSpiralSwitchover: Infinity,
        spiderfiedShadowColor: 'blue',
        nudgeStackedMarkers: false,
      })

      // Attach a listener for spiderfied markers
      this.oms.addListener('click', marker => {
        const { device } = marker // Pass your device data here
        this.onMarkerClick(device) // Reuse your existing method for showing InfoWindow
      })

      this.oms.addListener('spiderfy', () => {
        console.log('Markers spiderfied!')
      })

      this.oms.addListener('unspiderfy', () => {
        console.log('Markers unspiderfied!')
      })

      // Create the search box and link it to the UI element.
      const input = document.getElementById(this.id)
      // eslint-disable-next-line no-undef
      const searchBox = new google.maps.places.SearchBox(input)
      this.shapes = []
      this.map.addListener('bounds_changed', () => {
        searchBox.setBounds(this.map.getBounds())
      })

      let markers = []

      // Listen for the event fired when the user selects a prediction and retrieve
      // more details for that place.
      searchBox.addListener('places_changed', () => {
        const places = searchBox.getPlaces()
        // Clear out the old markers.
        markers.forEach(marker => {
          marker.setMap(null)
        })
        markers = []

        // eslint-disable-next-line no-undef
        const bounds = new google.maps.LatLngBounds()
        places.forEach(place => {
          if (!place.geometry || !place.geometry.location) {
            console.log('Returned place contains no geometry')
            return
          }

          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport)
          } else {
            bounds.extend(place.geometry.location)
          }
          this.center.lat = place.geometry.location.lat()
          this.center.lng = place.geometry.location.lng()
          this.locationAddress = place.formatted_address

          this.$emit('circleCenterSetFunc', this.center)
          this.$emit('locationAddressSetFunc', this.locationAddress)
        })
        this.map.fitBounds(bounds)
        this.map.setZoom(11)
      })
      this.drawCircle(this.centerOfCircle.lat, this.centerOfCircle.lng)
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(this.circle, 'dragend', () => {
        this.center.lat = this.circle.getCenter().lat()
        this.center.lng = this.circle.getCenter().lng()
      })
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(this.circle, 'radius_changed', () => {
        this.selectedRadius = this.circle.getRadius()
      })
      createResetZoomButton(this.map, this.resetZoom)
      // createSelectAllButton(this.map, this.toggleSelectAll)
    },
    distanceChanged(val) {
      this.circle.set('radius', Number(val * 1000))
    },
    circleFunc() {
      this.drawCircle(this.center.lat, this.center.lng)
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(this.circle, 'dragend', () => {
        this.center.lat = this.circle.getCenter().lat()
        this.center.lng = this.circle.getCenter().lng()
      })
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(this.circle, 'radius_changed', () => {
        this.selectedRadius = this.circle.getRadius()
      })
    },
    drawCircle(lat, lng) {
      this.circle = drawCircle(this.map, lat, lng, this.selectedRadius)
      this.circle.setMap(this.map)
    },
    devicesMarkerFunc(devicesPaths) {
      this.allDevicesFromParent = devicesPaths
      this.selectedDevices = this.$store.getters['pmpAds/getSelectedDevices']
      this.markers.forEach(marker => {
        marker.setMap(null)
      })
      this.markers = []
      if (this.markerClusterer) {
        this.markerClusterer.clearMarkers()
        this.markerClusterer = null
      }
      const markers = devicesMarkerFunc(
        this.map,
        devicesPaths,
        this.svgMarker,
        this.selectedSvgMarker,
        this.selectedDevices,
        this.onMarkerClick,
      )
      this.markers = markers

      this.markers.forEach(marker => {
        this.oms.addMarker(marker)
      })

      // eslint-disable-next-line no-new
      this.markerClusterer = markerclusterer(this.markers, this.map)
    },
    async  changeHandler() {
      this.circle.setMap(null)
      this.markers.forEach(marker => {
        marker.setMap(null)
      })
      this.selectedRadius = 0
    },
    onMarkerClick(device) {
      if (!this.shouldDisableOptions) {
        this.selectedDevices = this.$store.getters['pmpAds/getSelectedDevices']
        if (this.currentInfoWindow) {
          this.currentInfoWindow.close()
        }
        if (this.selectedMarkerIndex !== undefined) {
          this.markers[this.selectedMarkerIndex].setIcon('http://maps.google.com/mapfiles/ms/icons/green-dot.png')
        }
        this.isDeviceSelected = this.selectedDevices.some(d => d.id === device.id)
        const buttonText = this.isDeviceSelected ? 'Unselect' : 'Select'
        // eslint-disable-next-line no-undef
        const infoWindow = new google.maps.InfoWindow({
          content: `<div class="d-flex flex-column justify-content-center"><strong>Screen Name : ${device.name}</strong><br>
            <strong>Screen ID: ${device.id}</strong><br>
            <strong>Screen Impressions: ${Math.floor(device.impressions)}</strong><br>
            <strong>Lat: ${device.lat}, Lng: ${device.lng}</strong><br>
            <div class="d-flex justify-content-between">
            <button id="select-location-btn" class="btn btn-primary justify-content-center">${buttonText}</button>
            <button 
            id="view-location-btn" 
            class="btn btn-primary justify-content-center"
            deviceData="${device.id}"
            >View</button>
            </div>
            </div>
            `,
          ariaLabel: 'Device',
        })

        infoWindow.setPosition({ lat: device.lat, lng: device.lng })
        infoWindow.open(this.map)
        this.currentInfoWindow = infoWindow

        // eslint-disable-next-line no-undef
        google.maps.event.addListenerOnce(infoWindow, 'domready', () => {
          document.getElementById('select-location-btn').addEventListener('click', async () => {
            const markerToUpdate = this.markers.findIndex(marker => marker.id === device.id)
            if (this.isDeviceSelected) {
              // const selectedColor = createColorMarker(this.svgMarker, 'red')
              this.markers[markerToUpdate].setIcon(this.svgMarker)
              this.unselectLocation(device)
            } else {
              // const selectedColor = createColorMarker(this.svgMarker, 'green')
              this.markers[markerToUpdate].setIcon(this.selectedSvgMarker)
              this.selectLocation(device)
            }
            infoWindow.close()
          })
        })

        // eslint-disable-next-line no-undef
        google.maps.event.addListenerOnce(infoWindow, 'domready', () => {
          const button = document.getElementById('view-location-btn')
          button.addEventListener('click', async () => {
            const deviceData = await button.getAttribute('deviceData')
            const specificAddress = `${process.env.VUE_APP_LOCAL_URL}device/${deviceData}`
            window.open(specificAddress, '_blank')
            infoWindow.close()
          })
        })
      }
    },
    selectLocation(device) {
      this.$emit('locationSelected', device)
      this.selectedDevices = this.$store.getters['pmpAds/getSelectedDevices']
    },
    unselectLocation(device) {
      this.$emit('locationRemoved', device)
      this.selectedDevices = this.$store.getters['pmpAds/getSelectedDevices']
    },
    markMarkerUselected(device) {
      const markerToUpdate = this.markers.findIndex(marker => marker.id === device.id)
      // const selectedColor = createColorMarker(this.svgMarker, 'red')
      this.markers[markerToUpdate].setIcon(this.svgMarker)
      this.unselectLocation(device)
    },
    updateAlreadyExistingDevices(array) {
      this.selectedDevices = array
      this.selectedDevices = this.$store.getters['pmpAds/getSelectedDevices']
    },
    moveToLocation(lat, lng) {
      moveToLocation(this.map, lat, lng)
    },
    resetZoom() {
      this.map.setCenter(this.center)
      this.map.setZoom(10)
    },
    disableMapOptions() {
      return {
        disableDefaultUI: true,
        draggable: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
      }
    },
    selectAllDevices() {
      this.ifAllSelected = [...this.allDevicesFromParent]
      this.markers.forEach(marker => {
        marker.setIcon(this.selectedSvgMarker)
        this.$emit('allDevicesSelected', this.ifAllSelected)
      })
    },
    deselectAllDevices() {
      this.ifAllSelected = []
      this.markers.forEach(marker => {
        marker.setIcon(this.svgMarker)
        this.$emit('allDevicesDeselected')
      })
    },
  },
}
</script>

  <style lang="scss">
  @import "src/assets/scss/style";
  .map-com {
    #map {
      height: 100%;
    }

    .map-div {
      height: 250px !important;
    }

    html,
    body {
      height: 100%;
      margin: 0;
      padding: 0;
    }
  }

  </style>
