<template>
  <div class="edit-pmp-ad">
    <b-card>
      <div class="heading">
        <p>
          Edit External Ad
        </p>
      </div>
      <!-- form -->
      <validation-observer ref="editPmpAd">
        <b-form
          class="mt-2"
          style="
            width: 100%"
          @submit.prevent
        >
          <b-col
            md="9"
            class="mx-auto"
          >
            <b-form-group
              text-bolder
              label="External Ad Name"
              label-for="blog-edit-title"
              class="mb-2 campaign-label"
            >
              <validation-provider
                v-slot="{ errors }"
                name="External Ad Name"
                rules="required"
              >
                <b-skeleton
                  v-if="isPmpAds"
                  type="input"
                />
                <b-form-input
                  v-else
                  id="blog-edit-title"
                  v-model="postData.name"
                  placeholder="Please enter External Ad name here"
                  style="height: 42px"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <AddTimeSlotsAdCampaign
            ref="add-time-slots"
            :dates-for-time-slots="selectedDates"
            :array-of-dates-and-time-slots="finalDatesWithTimeSlots"
            :total-duration="totalDurationMedia"
            @addDatesWithTimeSlotsToArray="addDatesWithTimeSlotsToArray"
            @notSetTimeSlot="notSetTimeSlot"
          />
          <viewListDatesWithTimeSlots
            ref="view-time-slots"
            :array-of-dates-and-time-slots="finalDatesWithTimeSlots"
            @addDatesWithTimeSlotsToArrayAfterViewingList="addDatesWithTimeSlotsToArrayAfterViewingList"
          />
          <b-col
            md="9"
            lg="9"
            xl="9"
            class="mx-auto"
          >
            <div class="d-flex justify-content-center mt-2 ">
              <h3 class="heading">
                Audience
              </h3>
            </div>
            <b-row class="mt-1">
              <b-col>
                <b-form-group
                  text-bolder
                  label="Categories"
                  class="label mt-1"
                >
                  <v-select
                    v-model="postData.ad_category_ids"
                    class="zindex-4"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="adCategories"
                    :reduce="selectCategoriesType => selectCategoriesType.id"
                    placeholder="Select Categories"
                    multiple
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group
              text-bolder
              label="Location"
              class="label"
            >
              <validation-provider
                id="blog-map"
                name="Location"
              >
                <b-form-input
                  id="pac-input-edit-pmp-ad"
                  :value="postData.address"
                  placeholder="Search location here"
                  type="text"
                  autocomplete="off"
                  @change="locationChangeHandler"
                />
                <b-row class="mt-1">
                  <b-col
                    md="6"
                    lg="6"
                    xl="6"
                  >
                    <b-form-group
                      text-bolder
                      label="Select Radius"
                      class="label"
                    >
                      <b-skeleton
                        v-if="isPmpAds"
                        type="input"
                      />
                      <v-select
                        v-else
                        v-model="postData.radius"
                        class="zindex-2"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        select-size="3"
                        label="label"
                        :reduce="radius => radius.id"
                        :clearable="false"
                        :options="options"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="6"
                    lg="6"
                    xl="6"
                  >
                    <b-form-group
                      text-bolder
                      label="Select Venue Type"
                      class="label"
                    >
                      <v-select
                        v-model="venue_type"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="venueList"
                        input-id="venue-type"
                        label="venue_type_name"
                        class="search"
                        :reduce="selectVenueType => selectVenueType.id"
                        placeholder="Select Venue Type"
                        multiple
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-provider>
              <b-row class="d-flex justify-content-center">
                <b-col
                  cols="9"
                >
                  <div class="mt-2 mx-4">
                    <b-button
                      variant="primary"
                      block
                      :disabled="addLocationSpinner"
                      class="p-1 font-medium-2"
                      @click="getAndCountOfTheDevicesByArea()"
                    >
                      <div
                        v-if="addLocationSpinner"
                        class="spinner"
                      >
                        <b-spinner
                          small
                        />
                      </div>
                      Fetch Devices
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <br>
            </b-form-group>
          </b-col>
        </b-form>
      </validation-observer>
      <div class="map-div">
        <Map
          v-if="show"
          id="pac-input-edit-pmp-ad"
          ref="map-view-id-edit"
          :center-of-circle="postData.central_location"
          :radius="postData.radius"
          :selected-devices="selectedDevices"
          @circleCenterSetFunc="circleCenterSetFunc"
          @circleRadiusSetFunc="circleRadiusSetFunc"
          @devicesGetLatLong="devicesGetLatLong"
          @locationAddressSetFunc="locationAddressSetFunc"
          @locationSelected="locationSelected"
          @locationRemoved="locationRemoved"
          @allDevicesSelected="allDevicesSelected"
          @allDevicesDeselected="allDevicesDeselected"
        />
        <span
          v-else
        >
          <b-skeleton-img
            class="map-div"
          />
        </span>
      </div>
      <b-col
        md="12"
        class="mx-auto pt-2"
      >
        <b-card
          class="mt-1 card2 p-1"
        >
          <div class="d-flex justify-content-between pb-4">
            <span
              class="d-flex justify-items-between label labelSelectedDevices"
            >
              <span>Selected Devices</span>
              <span
                class="ml-2 badge bg-primary d-flex align-items-center"
              >
                Total Impressions : {{ Math.floor(totalImpressionsDevices) }}
              </span>
            </span>
            <b-button
              v-if="devicesPaths.length && !isAnyDeviceSelected"
              variant="secondary"
              @click="selectAllDevices()"
            >
              <feather-icon
                icon="PlusSquareIcon"
                class="cursor-pointer badgeSvg classButtonIcon"
                size="16"
              /> Select All
            </b-button>
            <b-button
              v-if="isAnyDeviceSelected"
              variant="secondary"
              class="classButton"
              @click="deselectAllDevices()"
            >
              <feather-icon
                icon="MinusSquareIcon"
                class="cursor-pointer badgeSvg classButtonIcon"
                size="16"
              /> Deselect All
            </b-button>
          </div>
          <span
            v-for="(item, index) in selectedDevices"
            :key="index"
          >
            <b-badge
              variant="success"
              class="mr-2 mb-2 px-2 py-1 cursor-pointer"
            >
              <div class="closediv closediv2">
                <b-button
                  variant="secondary"
                  class="btn-cross1"
                  @click="deleteDevice(item , index)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer badgeSvg"
                    size="16"
                  />
                </b-button>
              </div>
              <p
                class="text-id badge-class text-truncate"
                @click="goToMap(item)"
              >
                {{ item.name }}
              </p>
            </b-badge>
          </span>
        </b-card>
      </b-col>
      <!--/ form -->
      <BannerAdsMedia
        :max-time-limit="TimeLimitCondition.maxTargetAdsMediaCondition"
        @addMediaToCampaign="addMediaToPmpAd"
      />
      <AddedMediaToCampaign
        ref="add-media-cam-ref"
        :added-media="addedFilesToCampaign"
        :max-time-limit="TimeLimitCondition.maxTargetAdsMediaCondition"
        :is-draggable="true"
        @changeOnTotalDurationChanged="changeOnTotalDurationChanged"
        @removeMediaFromList="deleteMediaFromPmpAd"
        @changedOrderOfList="changedOrderOfList"
      />
      <b-row
        md="12"
        lg="12"
        class="mb-2 px-1"
      >
        <b-col
          md="4"
          lg="4"
        >
          <b-form-group
            label="Select Starting Date"
            label-for="blog-edit-category"
            class="mb-2 campaign-label"
          >
            <b-form-datepicker
              v-model="postData.start_date"
              :state="postData.start_date ? true : null"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          lg="4"
        >
          <b-form-group
            label="Select Ending Date"
            label-for="blog-edit-category"
            class="campaign-label"
          >
            <b-form-datepicker
              v-model="postData.end_date"
              :min="new Date(postData.start_date)"
              :disabled="!postData.start_date"
              :state="postData.start_date && postData.end_date ? new Date(postData.start_date) > new Date(postData.end_date) ? false : true : null"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          lg="4"
        >
          <b-form-group
            label="Select Time Zone"
            label-for="blog-edit-category"
            class="campaign-label"
          >
            <v-select
              v-model="postData.timezone"
              class="zindex-2"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              select-size="3"
              :clearable="false"
              :searchable="true"
              :options="timezones"
              placeholder="Select Time Zone"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-col
        md="12"
        class="mx-auto"
      >
        <Calendar
          ref="calendar"
          v-model="date"
          :attributes="attributes"
          is-expanded
          @dayclick="onDayClick"
        />
        <div
          class="d-flex justify-content-center"
        >
          <b-button
            variant="outline-primary"
            class="my-1 mx-1"
            :disabled="disableSetTime"
            @click="setTimeForSelectedDates"
          >
            Set Time
          </b-button>
          <b-button
            v-if="ifTimeSlotsExists"
            variant="outline-primary"
            class="my-1 mx-1"
            @click="editTimeForSelectedDates"
          >
            View Schedule
          </b-button>
        </div>
      </b-col>
      <div class="d-flex justify-content-center mt-0">
        <b-button
          variant="outline-primary"
          class="my-1"
          @click="reviewPmpAdsCampaign"
        >
          Review
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="m-1"
          :disabled="spinner"
          @click="editPmpAds"
        >
          <div
            v-if="spinner"
            class="spinner"
          >
            <b-spinner
              small
            />
          </div>
          Save
        </b-button>
        <b-button
          class="choose my-1"
          @click="cancelPmpAds"
        >
          Cancel
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BForm, BCol, BFormGroup, BFormInput, BButton, BSpinner, BSkeleton, BSkeletonImg, BRow, VBTooltip,
  BBadge,
  BFormDatepicker,
} from 'bootstrap-vue'
// import _ from 'lodash'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import moment from 'moment'
import Vue from 'vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { Calendar } from 'v-calendar'
import BannerAdsMedia from '@/components/common/BannerAdsMedia.vue'
import AddedMediaToCampaign from '@/components/common/AddedMediaToCampaign.vue'
import Map from '@/components/pmp-ads/pmpMap.vue'
import TimeLimitCondition from '@/common/config'
import store from '@/store'
import MemberPermissions from '@/common/enums/memberPermissionsEnum'
import { showToast } from '@/common/global/functions'
import {
  convertToSchedulerFormat,
  convertedDataComingFromSchedulerApi,
  convertDateToISOString,
  convertFromUTCToSchedulerTimeZoneFormat,
  convertToSchedulerFormatToUTC,
  getAllTimezones,
} from '@/common/global/calenderFunctions'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import AddTimeSlotsAdCampaign from '@/components/pmp-ads/AddTimeSlotsAdCampaign.vue'
import viewListDatesWithTimeSlots from '@/components/bannerads/viewListDatesWithTimeSlots.vue'
import AccountTypes from '@/common/enums/accountTypeEnum'

Vue.prototype.moment = moment
export default {
  name: 'EditPmpAd',
  components: {
    Map,
    vSelect,
    BCard,
    BForm,
    BCol,
    BFormGroup,
    BFormInput,
    AddedMediaToCampaign,
    BButton,
    BSkeleton,
    BannerAdsMedia,
    BSpinner,
    BFormDatepicker,
    BSkeletonImg,
    BRow,
    BBadge,
    // validations
    ValidationProvider,
    ValidationObserver,
    Calendar,
    AddTimeSlotsAdCampaign,
    viewListDatesWithTimeSlots,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    moment(date) {
      return moment(date).format('DD-MM-YYYY h:mm a')
    },
    momentDateForLocalTime(date) {
      return moment.utc(`${date.date} ${date.time.HH}:${date.time.mm}`, 'YYYY-MM-DD HH:mm').local().format('HH:mm')
    },
  },
  beforeRouteEnter(to, from, next) {
    const memberPermission = store.getters['user/getPermissionOfMember'](MemberPermissions.PMP_ADS)
    const adminRole = store.getters['user/getUserRole']
    if (memberPermission || (adminRole === AccountTypes.ADMIN)) {
      next()
    } else {
      next({
        name: 'dashboard',
      })
    }
  },
  data() {
    return {
      show: false,
      TimeLimitCondition,
      isPmpAds: false,
      spinner: false,
      addLocationSpinner: false,
      postData: {
        name: null,
        PmpAdId: JSON.parse(this.$route.params.id),
        radius: 0,
        media_ids: [],
        device_ids: [],
        start_date: new Date(),
        end_date: new Date(),
        address: '',
        central_location: [],
        ad_category_ids: [],
        timezone: null,
      },
      devicesPaths: [],
      addedFilesToCampaign: [],
      currentTimeSpan: '',
      limit: '',
      totalDevices: 0,
      options: [
        { label: ' 5 Km', id: 5 },
        { label: '10 Km', id: 10 },
        { label: '15 Km', id: 15 },
        { label: '20 Km', id: 20 },
        { label: '50 Km', id: 50 },
        { label: '75 Km', id: 75 },
        { label: '100 Km', id: 100 },
      ],
      number: {
        numeral: true,
      },
      // validation
      required,
      date: new Date(),
      ifTimeSlotsExists: false,
      disableSetTime: true,
      days: [],
      finalDatesWithTimeSlots: [],
      arrayOfDatesCollected: [],
      selectedDates: [],
      convertedDatesAndslots: [],
      venue_type: [],
      selectedDevices: [],
      existingDevices: [],
      totalDurationMedia: 0,
      adCategories: [],
      timezones: [],
      totalImpressionsDevices: 0,
    }
  },
  computed: {
    filteredDevices() {
      return this.allDevices.filter(device => !this.userSelectedDevices.map(res => res.id).includes(device.id))
    },
    dates() {
      return this.days.map(day => day.date)
    },
    attributes() {
      const currentAttributes = this.dates.map(date => ({
        highlight: true,
        dates: date,
      }))
      const previousAttributes = this.arrayOfDatesCollected.map(id => ({
        bar: 'red',
        dates: id,
      }))

      return [...currentAttributes, ...previousAttributes]
    },
    venueList() {
      return this.$store.getters['venues/getVenueTypeList']
    },
    isUserAdmin() {
      return this.$store.getters['user/getUserRole'] === AccountTypes.ADMIN
    },
    isAnyDeviceSelected() {
      return this.selectedDevices.length > 0
    },
  },
  watch: {
    devicesPaths() {
      if (this.$refs['map-view-id-edit']) {
        this.$refs['map-view-id-edit'].devicesMarkerFunc(this.devicesPaths)
      }
    },
    'postData.radius': {
      handler(value) {
        if (this.$refs['map-view-id-edit']) {
          this.$refs['map-view-id-edit'].selectedRadiusFunc(value)
        }
      },
    },
    selectedDevices: {
      handler(newValue) {
        this.totalImpressionsDevices = newValue.reduce((total, device) => total + Number(device.impressions || 0), 0)
      },
      deep: true, // Ensures watcher triggers for changes within array elements
    },
  },
  async created() {
    await this.$store.dispatch('venues/getVenueTypeList')
    if (this.isUserAdmin) {
      this.adCategories = await this.$store.dispatch('pmpAds/getAdminAdCategories')
    } else {
      this.adCategories = await this.$store.dispatch('pmpAds/getAdCategories')
    }
    this.timezones = await getAllTimezones()
    await this.getPmpAds(this.$route.params.id)
    if (this.pmpads.pmp_ads_scheduler_has_device.length) {
      await this.mapDataToCalendar()
    }
  },
  methods: {
    dateChangeHandler() {
      this.devicesPaths = []
      this.totalDevices = 0
    },
    locationChangeHandler() {
      this.$refs['map-view-id-edit'].changeHandler()
      this.postData.central_location = {}
      this.postData.address = null
      this.postData.radius = 0
      this.totalDevices = 0
    },
    async getPmpAds(id) {
      try {
        this.isPmpAds = true
        let response = null
        if (this.isUserAdmin) {
          response = await this.$store.dispatch('pmpAds/getOnePmpAdByAdmin', { id, userHasGroupId: this.$route.params.userId })
        } else {
          response = await this.$store.dispatch('pmpAds/getOnePmpAdByUser', { id })
        }
        this.pmpads = response
        this.postData = {
          ...this.postData,
          name: this.pmpads.name,
          radius: this.pmpads.radius,
          central_location: this.pmpads.central_location,
          address: this.pmpads.address,
          start_date: this.pmpads.start_date,
          end_date: this.pmpads.end_date,
          ad_category_ids: this.pmpads.ad_categories,
          timezone: this.pmpads.timezone,
        }
        const categoriesIds = []
        this.pmpads.ad_categories.map(category => categoriesIds.push(category.id))
        this.postData.ad_category_ids = categoriesIds
        const devicesArray = this.pmpads.pmp_ads_has_device
        await this.parsedDevices(devicesArray)
        const array = (this.pmpads.central_location.slice(6, -1)).split(' ')
        this.totalDevices = this.pmpads.pmp_ads_has_device.length
        await this.getCircleCenterPoints(array)
        await this.$refs['map-view-id-edit'].updateAlreadyExistingDevices(this.selectedDeviceIds)
        await this.getSelectedDevicesByArea(devicesArray)
        this.addedFilesToCampaign = this.pmpads.pmp_ads_has_media.map(res => res.media)
        this.updateIdsOfMediaInPayload()
        this.isPmpAds = false
      } catch (error) {
        console.error('An error occurred:', error.response)
      }
    },
    getCircleCenterPoints(array) {
      const emptyArray = []

      for (let i = 0; i < array.length; i += 2) {
        // Ensure we have both latitude and longitude values
        if (array[i] && array[i + 1]) {
          emptyArray.push({
            lat: JSON.parse(array[i + 1]),
            lng: JSON.parse(array[i]),
          })
        }
      }

      if (emptyArray.length > 0) {
        this.show = true
      }

      // eslint-disable-next-line prefer-destructuring
      this.postData.central_location = emptyArray[0]
    },
    getSelectedDevicesByArea(array) {
      let locationPoint = ''
      const emptyArray = []
      this.devicesPaths = []
      // eslint-disable-next-line no-restricted-syntax
      for (const device of array) {
        locationPoint = device.device.location
        const points = (locationPoint.slice(6, -1)).split(',')
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < points.length; i++) {
          const subArray = points[i].split(' ')
          emptyArray.push({
            lat: JSON.parse(subArray[1]), lng: JSON.parse(subArray[0]), name: device.device.name, id: device.device.id, impressions: device.device.impressions,
          })
        }
      }
      this.devicesPaths = JSON.parse(JSON.stringify(emptyArray))
    },
    updateIdsOfMediaInPayload() {
      this.postData.media_ids = this.addedFilesToCampaign.map(res => res.id)
    },
    async calculateAdImpressions(finalDatesWithTimeSlots, totalImpressionsDevices) {
      let totalslots = 0
      finalDatesWithTimeSlots.forEach(data => {
        totalslots += data.value.length
      })
      return totalslots * totalImpressionsDevices
    },
    async editPmpAds() {
      this.postData.start_date = await convertDateToISOString(this.postData.start_date)
      this.postData.end_date = await convertDateToISOString(this.postData.end_date)
      await this.getDeviceIDsBeforeSubmit()
      this.postData.scheduler = await convertToSchedulerFormat(this.finalDatesWithTimeSlots, this.postData.device_ids)
      this.postData.scheduler = await convertToSchedulerFormatToUTC(this.postData.timezone, this.postData.scheduler)
      this.$refs.editPmpAd.validate().then(async success => {
        if (success) {
          if (!this.postData.ad_category_ids.length) {
            showToast('Add External Ad', 'Please select at least one category!', 'danger', 4000)
          } else if (!this.postData.address) {
            showToast('Edit External Ad', 'Please add location to update External Ads', 'danger', 4000)
          } else if (this.postData.radius === 0) {
            showToast('Edit External Ad', 'Please add radius to update External Ads', 'danger', 4000)
          } else if (this.postData.device_ids.length > 0) {
            if (this.postData.scheduler.length === 0) {
              showToast('Edit External Ad', 'Please add schedule to update External Ads ', 'danger', 4000)
            } else if (this.addedFilesToCampaign.length > 0) {
              const totalAdImpression = await this.calculateAdImpressions(this.finalDatesWithTimeSlots, this.totalImpressionsDevices)
              this.$swal({
                title: '<span class="font-weight-bolder">Book External Ad?</span>',
                icon: 'warning',
                html: `You want to book External Ad with ${this.postData.device_ids.length} devices<br>Total Ad Impressions: <span class="badge bg-primary mt-1">${Math.floor(totalAdImpression)}</span>`,
                showCloseButton: false,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: 'Save',
                confirmButtonAriaLabel: 'Thumbs up, great!',
                cancelButtonAriaLabel: 'Thumbs down',
                width: '550px',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                  icon: 'primary',
                },
                buttonsStyling: false,
              })
                .then(result => {
                  if (result.value) {
                    this.editPmpAdsData()
                  }
                })
            } else {
              showToast('Edit External Ad', 'Please add media files to update External Ads', 'danger', 4000)
            }
          } else {
            showToast('Edit External Ad', 'Please add devices to update External Ads', 'danger', 4000)
          }
        }
      })
    },
    async editPmpAdsData() {
      try {
        this.spinner = true
        if (this.isUserAdmin) {
          await this.$store.dispatch('pmpAds/editPmpAdsDataByAdmin', { PmpAdId: this.postData.PmpAdId, postData: this.postData, userHasGroupId: this.$route.params.userId })
          this.$router.push(this.isUserAdmin ? `/users/${this.$route.params.userId}/user-pmp-ads` : '/pmp-ads')
          return
        }
        await this.$store.dispatch('pmpAds/editPmpAdsDataByUser', { PmpAdId: this.postData.PmpAdId, postData: this.postData, userHasGroupId: this.$route.params.userId })
        this.$router.push(this.isUserAdmin ? `/users/${this.$route.params.userId}/user-pmp-ads` : '/pmp-ads')
      } catch (error) {
        console.error('An error occurred:', error.response)
      } finally {
        this.spinner = false
      }
    },
    async getAndCountOfTheDevicesByArea() {
      this.addLocationSpinner = true
      if (Object.keys(this.postData.central_location).length === 0) {
        showToast('Edit External Ad', 'Please select the location!', 'danger', 4000)
      } else if (this.postData.radius === 0) {
        showToast('Edit External Ad', 'Please select the radius!', 'danger', 4000)
      } else if (!this.postData.ad_category_ids.length) {
        showToast('Add External Ad', 'Please select at least one category!', 'danger', 4000)
      } else {
        let requestBody = {}
        if (!this.venue_type.length) {
          requestBody = {
            location: this.postData.central_location,
            radius: this.postData.radius,
            device_categories: this.postData.ad_category_ids,
          }
        } else {
          requestBody = {
            location: this.postData.central_location,
            radius: this.postData.radius,
            venue_type: this.venue_type,
            device_categories: this.postData.ad_category_ids,
          }
        }
        let response = null
        try {
          if (this.isUserAdmin) {
            response = await this.$store.dispatch('pmpAds/getAvailableDevicesByAdmin', { postData: requestBody })
          } else {
            response = await this.$store.dispatch('pmpAds/getAvailableDevicesByUser', { postData: requestBody })
          }
        } catch (error) {
          console.error('An error occurred:', error.response)
        } finally {
          this.addLocationSpinner = false
        }
        this.totalDevices = response.count
        const { deviceArray } = response
        if (this.totalDevices === 0) {
          showToast('Add External Ad', 'No devices available at this time in this location!', 'danger', 4000)
          await this.devicesGetLatLong(deviceArray)
        }
        await this.devicesGetLatLong(deviceArray)
      }
      this.addLocationSpinner = false
    },
    circleCenterSetFunc(center) {
      this.postData.central_location = center
    },
    circleRadiusSetFunc(radius) {
      this.postData.radius = Number(radius / 1000)
    },
    locationAddressSetFunc(address) {
      this.postData.address = address
    },
    devicesGetLatLong(array) {
      this.devicesPaths = []
      const paths = []
      array.forEach(data => {
        paths.push({
          lat: data.location.y, lng: data.location.x, name: data.name, id: data.id, impressions: data.impressions,
        })
      })
      this.devicesPaths = JSON.parse(JSON.stringify(paths))
      return this.devicesPaths
    },
    cancelPmpAds() {
      this.$router.back()
    },
    addMediaToPmpAd(media) {
      if (this.finalDatesWithTimeSlots.length > 0) {
        this.$swal.fire({
          text: 'All selected time slots will be lost!! are you sure you want to proceed?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Add',
          cancelButtonText: 'Cancel',
        }).then(result => {
          if (result.isConfirmed) {
            this.resetAllDatesOfCalendar()
            this.addedFilesToCampaign.push(media)
            this.updateIdsOfMediaInPayload()
          }
        })
      } else {
        this.addedFilesToCampaign.push(media)
        this.updateIdsOfMediaInPayload()
      }
    },
    deleteMediaFromPmpAd(index) {
      if (this.finalDatesWithTimeSlots.length > 0) {
        this.$swal.fire({
          text: 'All selected time slots will be lost!! are you sure you want to proceed?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Remove',
          cancelButtonText: 'Cancel',
        }).then(result => {
          if (result.isConfirmed) {
            this.resetAllDatesOfCalendar()
            this.addedFilesToCampaign.splice(index, 1)
            this.updateIdsOfMediaInPayload()
          }
        })
      } else {
        this.addedFilesToCampaign.splice(index, 1)
        this.updateIdsOfMediaInPayload()
      }
    },
    changedOrderOfList(array) {
      this.addedFilesToCampaign = array
      this.updateIdsOfMediaInPayload()
    },
    resetAllDatesOfCalendar() {
      this.finalDatesWithTimeSlots = []
      this.dates = []
      this.arrayOfDatesCollected = []
      this.setAllDatesAvailable()
      this.disableSetTime = true
      this.ifTimeSlotsExists = false
    },
    async reviewPmpAdsCampaign() {
      if (this.addedFilesToCampaign) {
        await this.$store.dispatch('lightBox/lightBoxData', this.addedFilesToCampaign)
      }
    },
    async onDayClick(day) {
      if (this.totalDurationMedia <= 0) {
        this.$swal.fire({
          text: 'Please add Media. Media is required to add slots.',
          icon: 'warning',
        })
      }
      const idx = this.days.findIndex(d => d.id === day.id)
      if (idx >= 0) {
        this.days.splice(idx, 1)
        if (this.days.length === 0) {
          this.disableSetTime = true
          this.setAllDatesAvailable()
        }
      } else {
        this.disableSetTime = false
        this.days.push({
          id: day.id,
          date: day.date,
        })
      }
    },
    setTimeForSelectedDates() {
      this.days.forEach(element => this.selectedDates.push(element.id))
      this.$refs['add-time-slots'].show()
    },
    async editTimeForSelectedDates() {
      this.setAllDatesAvailable()
      this.disableSetTime = true
      this.$refs['view-time-slots'].show()
    },
    addDatesWithTimeSlotsToArray(array) {
      this.days.forEach(element => this.arrayOfDatesCollected.push(element.id))
      this.days = []
      this.selectedDates = []
      this.disableSetTime = true
      this.setAllDatesAvailable()
      this.finalDatesWithTimeSlots = array
      if (this.finalDatesWithTimeSlots.length > 0) {
        this.ifTimeSlotsExists = true
      }
    },
    notSetTimeSlot() {
      this.setAllDatesAvailable()
      this.disableSetTime = true
    },
    setAllDatesAvailable() {
      this.days = []
      this.selectedDates = []
    },
    async addDatesWithTimeSlotsToArrayAfterViewingList(array) {
      this.arrayOfDatesCollected = []
      this.finalDatesWithTimeSlots = array
      await this.finalDatesWithTimeSlots.forEach(element => this.arrayOfDatesCollected.push(element.key))
      if (this.finalDatesWithTimeSlots.length === 0) {
        this.ifTimeSlotsExists = false
      }
    },
    async mapDataToCalendar() {
      this.pmpads.pmp_ads_scheduler_has_device = await convertFromUTCToSchedulerTimeZoneFormat(this.pmpads.timezone, this.pmpads.pmp_ads_scheduler_has_device)
      this.pmpads.pmp_ads_scheduler_has_device.forEach(slots => {
        this.arrayOfDatesCollected.push(slots.start_date_time.split('T')[0])
      })
      this.postData.start_date = this.pmpads.start_date
      this.postData.end_date = this.pmpads.end_date
      const leastDate = this.arrayOfDatesCollected
        .map(date => new Date(date))
        .sort((a, b) => a - b)[0]
      this.$refs.calendar.move({ month: leastDate.getMonth() + 1, year: leastDate.getFullYear() })
      this.finalDatesWithTimeSlots = await convertedDataComingFromSchedulerApi(this.pmpads.pmp_ads_scheduler_has_device)
      this.ifTimeSlotsExists = true
    },
    locationSelected(device) {
      this.selectedDevices.push(device)
      this.$store.commit('pmpAds/SET_SELECTED_DEVICES', this.selectedDevices)
    },
    locationRemoved(device) {
      this.selectedDevices = this.selectedDevices.filter(d => d.id !== device.id)
      this.$store.commit('pmpAds/SET_SELECTED_DEVICES', this.selectedDevices)
    },
    async parsedDevices() {
      this.selectedDevices = await this.pmpads.pmp_ads_has_device.map(deviceEntry => {
        const location = deviceEntry.device.location.match(/POINT\(([^ ]+) ([^ ]+)\)/)
        return {
          id: deviceEntry.device.id,
          name: deviceEntry.device.name,
          lat: parseFloat(location[2]),
          lng: parseFloat(location[1]),
          impressions: deviceEntry.device.impressions,
        }
      })
      await this.selectedDevices.map(deviceEntry => this.existingDevices.push(deviceEntry))
      this.$store.commit('pmpAds/SET_SELECTED_DEVICES', this.existingDevices)
      if (this.$refs['map-view-id-edit']) {
        this.$refs['map-view-id-edit'].devicesMarkerFunc(this.existingDevices)
      }
    },
    deleteDevice(device, index) {
      this.selectedDevices.splice(index, 1)
      this.$refs['map-view-id-edit'].markMarkerUselected(device)
      this.$store.commit('pmpAds/SET_SELECTED_DEVICES', this.selectedDevices)
    },
    goToMap(item) {
      this.$refs['map-view-id-edit'].moveToLocation(item.lat, item.lng)
    },
    getDeviceIDsBeforeSubmit() {
      this.postData.device_ids = this.selectedDevices.map(device => device.id)
    },
    changeOnTotalDurationChanged(duration) {
      this.totalDurationMedia = duration
    },
    allDevicesSelected(allDevicesIDs) {
      this.selectedDevices = allDevicesIDs
      this.$store.commit('pmpAds/SET_SELECTED_DEVICES', this.selectedDevices)
    },
    allDevicesDeselected() {
      this.selectedDevices = []
      this.$store.commit('pmpAds/SET_SELECTED_DEVICES', this.selectedDevices)
    },
    selectAllDevices() {
      this.$refs['map-view-id-edit'].selectAllDevices()
    },
    deselectAllDevices() {
      this.$refs['map-view-id-edit'].deselectAllDevices()
    },
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .edit-pmp-ad{
    #map {
      height: 100%;
    }
    .search {
      .vs__dropdown-menu {
        max-height: 170px !important;
        overflow-y: auto !important;
        width: 320px !important;
      }
      .vs__dropdown-toggle{
        max-height: 60px !important;
        overflow-y: auto !important;
        width: 320px !important;
      }
    }
    .map-div{
      height: 400px !important;
    }
    .heading{
      font-size: 20px;
      line-height: 44px;
      color: #1f58b5;
      font-weight: 600;
      font-family: "Montserrat",serif;
      text-align: center;
    }
    .vs__search{
      height: 35px !important;
    }
    //.form-control{
    //  height: 42px !important;
    //}
    .b-skeleton-img{
      height: 400px !important;
    }
    .campaign-label{
      font-size: 13px;
      line-height: 18px;
      color: #323232;
      font-weight: 600;
      font-family: "Montserrat";
    }
    #calender {
      .dropdown-menu{
        z-index: 12 !important;
      }
    }
    #time{
      .vue__time-picker .controls .char{
        display: none !important;
      }
    }
  }
  .search1 {
    .vs__dropdown-menu {
      max-height: 150px !important;
      overflow-y: auto !important;
      width: 380px !important;
    }
    .vs__dropdown-toggle{
      max-height: 60px !important;
      overflow-y: auto !important;
      width: 380px !important;
    }
  }
  .vc-bars{
    width: 20% !important;
  }
  .vc-day-content:focus {
    font-weight: normal !important;
  }
  .text-id{
    font-size: medium;
    max-width: 200px !important;
    margin-bottom: 0px !important;
  }
  .closediv2{
    position: relative;
    border-radius: 50%;
    bottom: 20px !important;
    left: 27px !important;
    top: auto;
    cursor: pointer;
  }
  .btn-cross1{
    border-radius: 50%;
    margin: 0px;
    padding: 0px;
    top: none !important;
    right: none !important;
    left: none !important;
  }
  .badgeSvg{
    height: 15px !important;
    width: 15px !important;
  }
  .btn-cross1:hover{
    color: #ea5455 !important;
  }
  .card2{
    width: 100%;
    //height: 185px;
    border-radius: 10px;
    background-color: #eeeeee;
    margin-right: 41px;
    //overflow-y: scroll;
  }
  .labelSelectedDevices{
    font-size: large;
  }
  @media (max-width: 750px) {
    .text-id{
      font-size: 12px;
      max-width: 150px !important;
      margin-bottom: 0px !important;
    }
    .classButton{
      font-size: 12px;
    }
    .classButtonIcon{
      display: none;
    }
    .labelSelectedDevices{
      font-size: small;
    }
  }
  </style>
